document.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('[data-filter]').forEach(function(element) {
    const filter = function () {
      const value = element.value
      const target = document.querySelector(element.dataset.filter)

      if (value) {
        target.querySelectorAll('[data-filter-category]').forEach((e) => e.style.display = 'none')
        target.querySelectorAll(`[data-filter-category="${value}"]`).forEach((e) => e.style.display = 'block')
      } else {
        target.querySelectorAll('[data-filter-category]').forEach((e) => e.style.display = 'block')
      }
    }
    element.addEventListener('change', filter)
    filter()
  })
})
