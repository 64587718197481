import Noty from 'noty'

window.showFlash = function (text, isError) {
  new Noty(
    {
      closeWith: ['button', 'click'],
      layout: 'topCenter',
      theme: 'bootstrap-v4',
      timeout: 3000,
      type: isError ? 'error' : 'success',
      text: text
    }
  ).show()
}