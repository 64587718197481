// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import "polyfill"

import ColorPicker from "colorpicker"
ColorPicker.buildColorPickersOnLoad()

import "helpers"
import "bootstrap"
import "alert"
import "error"
import "file"
import "flash"
import "tabs"
import "carousel"
import "dropdown"
import "filter"
import "font_preview"
import "site_controllers"
import "controllers"

import "site/animations"
import "site/link"
import "site/countdown"
import "site/infinite_scroll"
import "site/subscribers"
import "site/unsubscribe"
import "site/form_responses"
import "site/hcaptcha"
import "site/product"
import "site/order"
import "site/navigation"
import "site/cookies_notice"
import "site/tel"

Turbolinks.setProgressBarDelay(0)
