document.addEventListener("turbolinks:load", handleTabs)

window.addEventListener('worksaver:restored', handleTabs)

document.addEventListener('block:added', handleTabs)

window.addEventListener('canvas:changed', handleTabs)

function handleTabs() {

  document.querySelectorAll('.tabs').forEach(function (element) {
    if (element._tabsHandled) {
      return
    } else {
      element._tabsHandled = true
    }

    const items = Array.from(element.querySelectorAll('.tabs-item'))

    const contentContainer = element.querySelector('.tabs-content')

    const selectItem = function (item) {
      item.classList.add('tabs-item-selected')
      if (contentContainer._origin === item._content) return

      if (contentContainer._origin) {
        contentContainer._origin.innerHTML = ''
        Array.from(contentContainer.children).forEach((child)=>{
          contentContainer._origin.appendChild(child)
        })
      }

      contentContainer._origin = item._content
      contentContainer.innerHTML = ''
      Array.from(item._content.children).forEach((child)=>{
        contentContainer.appendChild(child)
      })
      item._content.innerHTML = contentContainer.innerHTML
      element.dispatchEvent(new Event('tab:selected', { bubbles: true}))
    }

    const handleTabItemClick = function (e) {
      items.forEach(function (it) { it.classList.remove('tabs-item-selected') })
      selectItem(e.currentTarget)
    }

    items.forEach(function(item) {
      item._content = item.querySelector('.tabs-item-content')

      if (item == items[0]) {
        selectItem(item)
      } else {
        item.classList.remove('tabs-item-selected')
      }

      item.addEventListener('click', handleTabItemClick)

      item.addEventListener('block:cloned', function (e) {
        items.push(e.clone)
        e.clone._content = e.clone.querySelector('.tabs-item-content')
        e.clone.classList.remove('tabs-item-selected')
        e.clone.addEventListener('click', handleTabItemClick)
      })
    })

    contentContainer.addEventListener('block:changed', function () {
      if (!contentContainer._origin) return
      contentContainer._origin.innerHTML = contentContainer.innerHTML
    })
  })
  
}