var WebFont = require('webfontloader')
const FONT_LOADING_CLASS = 'loading-font'

document.addEventListener("turbolinks:load", function() {
  const familySelects = document.querySelectorAll('select[data-font-preview]')
  if (familySelects.length === 0) return

  const previewElements = new Array()
  const families = new Array()

  familySelects.forEach(function (select) {
    families.push(select.value)
    previewElements.push(document.querySelector(select.dataset.fontPreview))
  })

  loadWebFonts(families, previewElements)

  for (let i = 0; i < familySelects.length; ++i) {
    const select = familySelects[i]
    const previewElement = previewElements[i]
    if (select && previewElement) {
      select.addEventListener('change', function () {
        previewElement.classList.add(FONT_LOADING_CLASS)
        loadWebFonts([select.value], [previewElement])
      })
    }
  }
})

function loadWebFonts (families, targets) {
  WebFont.load({
    google: {
      families: families
    },
    active: function () {
      for (let i = 0; i < targets.length; ++i) {
        if (targets[i] && families[i]) {
          targets[i].style.fontFamily = families[i]
          targets[i].classList.remove(FONT_LOADING_CLASS)
        }
      }
    },
    classes: false
  })
}