import Rails from '@rails/ujs'


document.addEventListener("turbolinks:load", function() {
  const forms = document.querySelectorAll('form[action="/form_responses"]')

  if (!forms) {
    return
  }

  forms.forEach((form)=>{
    form.removeAttribute('data-remote')
    form.dataset.local = "true"
    const formName = form.dataset.name
    if (formName) {
      let input = form.querySelector('input[name="form_name"]')
      if (!input) {
        input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'form_name'
        form.appendChild(input)
      }
      input.value = formName
    }

    form.addEventListener('submit', (e) => {
      e.preventDefault()
      Rails.ajax({
        type: "POST",
        url: form.action,
        data: new FormData(form),
        success: (_) => {
          window.location.reload()
        },
        error: (_) => {
          window.location.reload()
        }
      })
    })
  })
})
