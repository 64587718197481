import Rails from '@rails/ujs'

document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('form[action^="/subscribers"]').forEach((form) => {
    form.removeAttribute('data-remote')

    const submitInputs = form.querySelectorAll('[type="submit"]')
    submitInputs.forEach((input) => {
      input.addEventListener('click', (e) => {
        submitInputs.forEach((i) => i.removeAttribute('data-clicked'))
        input.dataset.clicked = true
      })
    })

    form.addEventListener('submit', function (e) {
      e.preventDefault()

      const params = new URLSearchParams(window.location.search)

      if (params.has('single_optin')) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'single_optin'
        input.value = 'true'
        form.appendChild(input)
      }

      const formData = new FormData(form)

      const submitButtonWithTags = form.querySelector('[type="submit"][data-tags][data-clicked]')

      if (submitButtonWithTags && submitButtonWithTags.dataset.tags) {
        Array.from(submitButtonWithTags.dataset.tags.split(',')).forEach((tag) => {
          formData.append('subscriber[tags][]', tag)
        })
      }

      const enableSubmitButtons = (_) => {
        form.querySelectorAll('[data-disable-with]')
          .forEach((element) => element.removeAttribute('disabled'))
        form.querySelectorAll('[data-clicked]')
          .forEach((element) => element.removeAttribute('data-clicked'))
      }

      Rails.ajax({
        type: "POST",
        url: form.action,
        data: formData,
        success: enableSubmitButtons,
        error: enableSubmitButtons
      })
    })
  })
})
