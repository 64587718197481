import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['accountInput', 'settingInput']

  connect () {
    this.restoreVariables()
  }

  toggleSettings (event) {
    event.preventDefault()
    this.element.classList.toggle('show-settings')
  }

  useTemplate (event) {
    event.preventDefault()
    const params = {}
    this.settingInputTargets.forEach((input) => {
      const value =  window.sessionStorage.getItem(input.dataset.variableName)
      if (value) params[input.dataset.attributeName] = value
    })
    const queryString = new URLSearchParams(params)
    let url = `${event.target.href}?${queryString}`

    window.location.href = url
  }

  setRootVariable (event) {
    const target = event.target
    const variableName = target.dataset.variableName
    let value = target.value
    if (variableName.includes('font')) value = `"${value}"`
    const property = `--site-${variableName}`
    document.documentElement.style.setProperty(property, value)
    if (window.sessionStorage) window.sessionStorage.setItem(variableName, target.value)
  }

  restoreVariables (event) {
    if (!window.sessionStorage) return

    if (this.hasAccountInputTarget) {
      this.accountInputTargets.forEach((input) => {
        if (input.value) {
          window.sessionStorage.setItem(input.dataset.variableName, input.value) 
        } else if (this.element.dataset.restoreFromSession) {
          input.value = window.sessionStorage.getItem(input.dataset.variableName)
        }
      })
    } else if (this.hasSettingInputTarget) {
      this.settingInputTargets.forEach((input) => {
        const value =  window.sessionStorage.getItem(input.dataset.variableName)
        if (!value) return
        const property = `--site-${input.dataset.variableName}`
        document.documentElement.style.setProperty(property, value)
        input.value = value
        
        if (input.hasAttribute('data-colorpicker')) {
          setTimeout(function () {
            // Set timeout is necessary for the color to be applied
            input.dispatchEvent(new Event('pickr-change'))
          }, 100)
        }
      })
    }
  }
}
