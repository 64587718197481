import Noty from "noty"

document.addEventListener("turbolinks:load", function() {
  const alerts = document.querySelectorAll(".alert.flash")

  if (!alerts) {
    return
  }

  alerts.forEach(function (alert) {
    alert.parentNode.removeChild(alert)

    if (!alert.innerText) {
      return
    }

    let type = 'info'

    if (alert.classList.contains('alert-success')) {
      type = 'success'
    } else if (alert.classList.contains('alert-danger')) {
      type = 'error'
    }

    new Noty({
      closeWith: ['button', 'click'],
      layout: 'topCenter',
      text: alert.innerText,
      theme: 'bootstrap-v4',
      timeout: 3000,
      type: type
    }).show()
  })
})
