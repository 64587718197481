document.addEventListener('turbolinks:load', function () {
  let locale
  const queryString = window.location.search

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    locale = urlParams.get('locale')
  }

  document.querySelectorAll('.navbar a.navbar-brand, .navbar a.nav-link').forEach(
    function(navLink) {
      if (!navLink.href) return
      const link = new URL(navLink.href)
      if (locale) {
        link.searchParams.set('locale', locale)
      }
      navLink.href = link.href

      if (!navLink.classList.contains('dropdown-item') && navLink.href === window.location.toString()) {
        navLink.classList.add('active')
      }
    }
  )
})