import { WOW } from 'wowjs'

const style = document.createElement('style')
style.textContent = `.site-scroll-animation {
  visibility: hidden;
}`
document.head.append(style)

const wow = new WOW(
  {
    boxClass:     'site-scroll-animation',      // animated element css class (default is wow)
    animateClass: 'animate__animated', // animation css class (default is animated)
    offset:       0,          // distance to the element when triggering the animation (default is 0)
    mobile:       true,       // trigger animations on mobile devices (default is true)
    live:         true,       // act on asynchronously loaded content (default is true)
    callback:     function(box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null // optional scroll container selector, otherwise use window
  }
)
wow.init()

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('[data-animation]').forEach(function (element) {
    element.classList.add('site-scroll-animation')
    element.classList.add(`animate__${element.dataset.animation}`)
  })
  wow.sync()
})
