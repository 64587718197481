import InfiniteScroll from "infinite-scroll"

document.addEventListener("turbolinks:load", function() {
  let i = 0
  const scrollables = document.querySelectorAll('.infinite-scroll')
  scrollables.forEach((scrollable)=>{
    scrollable.id = `infinite-scroll-${ i++ }`
    scrollable.classList.add(scrollable.id)
    const element = scrollable.querySelector(".paginated")
    let nextElement = scrollable.querySelector("nav.pagination a[rel=next]")

    if (!element || !nextElement) {
      return
    }

    const infScroll = new InfiniteScroll(element, {
      append: scrollable.dataset.item,
      hideNav: `#${scrollable.id} nav.pagination`,
      path: function () {
        if (nextElement) {
          return nextElement.href
        }
      },
      checkLastPage: 'nav.pagination a[rel=next]',
      history: (scrollables.length === 1 ? 'push' : false),
      status: `#${scrollable.id} .page-load-status`,
      onInit: function () {
        scrollable.querySelector('.page-load-status').classList.remove('d-none')
      }
    })

    infScroll.on('load', function(response) {
      nextElement = response.querySelector('nav.pagination a[rel=next]')
    })
  })
})
