import 'slick-carousel';

document.addEventListener("turbolinks:load", handleCarousels)

window.addEventListener('worksaver:restored', handleCarousels)

document.addEventListener('block:added', handleCarousels)

window.addEventListener('block:cloned', handleCarousels)

window.addEventListener('canvas:changed', handleCarousels)

window.addEventListener('tab:selected', (e) => { refreshCarousels(e.target, '.tabs-content') })

function handleCarousels() {
  document.querySelectorAll('[data-slick]').forEach(function(element) {
    if (element._carouselHandled) {
      return
    }

    if (element.classList.contains('slick-initialized')) {
      manuallyUnslick(element)
    }

    let options = JSON.parse(element.dataset.slick)

    const responsiveOptions = [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          variableWidth: true
        }
      }
    ]

    const inEditor = window.location.pathname.startsWith('/admin/pages/')

    $(element).slick({
      infinite: !inEditor,
      responsive: responsiveOptions
    })

    if (inEditor) {
      $(element).slick('slickGoTo', 2)
    }

    if (options.autoplay) {
      $(element).slick('slickSetOption', 'autoplay', true, true)
    }

    element.addEventListener('block:changed', function (e) {
      const newOptions = JSON.parse(element.dataset.slick)

      for (const optionName in newOptions) {
        if (options[optionName] !== newOptions[optionName]) {
          $(element).slick('slickSetOption', optionName, newOptions[optionName], true)
        }
      }

      options = newOptions
    })

    element.addEventListener('block:cloned', function (e) {
      if (e.clone.classList.contains('slick-slide')) {
        e.clone.remove()
        const index = parseInt(e.clone.dataset.slickIndex)
        $(element).slick('slickAdd', e.clone, index)
      }
    })

    element.addEventListener('block:removed', function (e) {
      /* clones .carousel element before slick-slide refresh */
      const elementClone = element.cloneNode(true)
      if (e.data.block.classList.contains('slick-slide')) {
        /* stores .carousel items' attributes */
        const attributes = Array.from(elementClone.querySelectorAll('.carousel-page')).map(x => x.attributes)
        /* wipes .carousel items's attributes to reinitialize them */
        $(element).slick('refresh')
        const pages = Array.from(element.querySelectorAll('.carousel-page'))
        /* restores previously wiped attributes */
        for (let i = 0; i < pages.length; i++) {
          for(let j = 0, l = attributes[i].length; j < l; ++j){
            const nodeName  = attributes[i].item(j).nodeName
            const nodeValue = attributes[i].item(j).nodeValue
            pages[i].setAttribute(nodeName, nodeValue)
          }
        }
      }
    })

    element._carouselHandled = true
  })
}

function manuallyUnslick(element) {
  const pages = element.querySelectorAll('.carousel-page')
  element.innerHTML = ''

  pages.forEach(function (page) {
    element.appendChild(page)
  })

  element.classList.remove('slick-initialized')
}

function refreshCarousels(element, selector) {
  element.querySelectorAll(`${selector} [data-slick]`).forEach((carouselElement)=>{
    if (carouselElement._carouselHandled) {
      $(carouselElement).slick('refresh')
    }
  })
}
