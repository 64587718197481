import bsCustomFileInput from 'bs-custom-file-input'
import { DirectUpload } from "@rails/activestorage"
import Dropzone from 'dropzone'

const FILE_HEADERS = {
  'image/png': '89504e47',
  'image/gif': '47494638',
  'image/jpeg': 'ffd8ff',
  'image/tiff': '4d4d002a',
  'image/bmp': '424d',
  'application/pdf': '25504446'
}

export function checkFileContentType(file, allowedTypes, callback) {
  const blob = file.slice(0, 4)

  const fileReader = new FileReader()

  fileReader.onloadend = function (e) {
    const arr = new Uint8Array(e.target.result)
    const header = arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue.toString(16), ''
    )
    const isValid = Array.from(allowedTypes).some(
      (type) => header.startsWith(FILE_HEADERS[type])
    )
    callback(isValid)
  }

  fileReader.readAsArrayBuffer(blob);
}

document.addEventListener("turbolinks:load", function() {
  bsCustomFileInput.init()
  handleFileInputs()
  handleDropzones()
})

function handleFileInputs () {
  document.querySelectorAll('input[data-verify-headers]').forEach((input)=>{
    const acceptedTypes = input.accept.split(',')
    let label, originalLabelContent

    input.labels.forEach((element) => {
      if (element.classList.contains('custom-file-label')){
        label = element
      }
    })

    input.addEventListener('change', (e) => {
      let allow = true
      for(let i= 0; i < input.files.length; ++i) {
        const file = input.files[i]
        if (!allow || !file) {
          return false
        }
        checkFileContentType(file, acceptedTypes, (isValid) => {
          if (!isValid) {
            e.preventDefault()
            input.value = null
            allow = false
            showFlash(I18n.file.unsupported_format, true)
            if (label) label.textContent = I18n.choose_an_image
          }
        })
      }
    })
  })
}

function handleDropzones () {
  document.querySelectorAll('.drop-zone').forEach((container) => {
    let filesToProcess = new Array()
    const urls = Array.from(container.dataset.files.split(',')).filter((url) => !!url)

    const dropzone = new Dropzone(container, {
      paramsName: container.dataset.name,
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: I18n.remove,
      maxFiles: 8,
      url: '/',
      acceptedFiles: container.dataset.accept
    })

    urls.forEach((url) => {
      dropzone.displayExistingFile({ name: url.split('/').pop(), size: 12345}, url, null, "anonymous")
      const input = document.createElement('input')
      input.type = "hidden"
      input.value = url.match(window.Helpers.activeStorageBlobExtractor)[1]
      input.name = container.dataset.name
      container.appendChild(input)
    })

    dropzone.on('addedfile', (file) => {
      filesToProcess.push(file)
    })

    dropzone.on('thumbnail', (file, dataURL) => {
      if (filesToProcess.includes(file)) return
      const sizeElement = file.previewElement.querySelector('.dz-size [data-dz-size]')
      sizeElement.style.display = 'none'
    })

    dropzone.on('removedfile', (file) => {
      filesToProcess = filesToProcess.filter((f) => f !== file)
      const blobSignedId = file.dataURL.match(window.Helpers.activeStorageBlobExtractor)[1]
      container.querySelector(`input[value="${blobSignedId}"]`).remove()
    })

    const form = container.closest('form')
    const fileInputsForDirectUpload = form.querySelectorAll('input[data-direct-upload-url]')

    const submitLtn = function (e) {
      processFiles(e, filesToProcess, fileInputsForDirectUpload, container)
      form.removeEventListener('submit', this)
    }

    form.addEventListener('submit', submitLtn)
  })

  function processFiles (e, filesToProcess, fileInputsForDirectUpload, container) {
    if (filesToProcess.length == 0) return

    e.preventDefault()
    let queueSize = filesToProcess.length
    const submitButtons = e.target.querySelectorAll('input[data-disable-with]')
    submitButtons.forEach((button) => button.disabled = true)
    container.classList.add('lock')

    fileInputsForDirectUpload.forEach((input) => {
      input.removeAttribute('data-direct-upload-url')
    })

    container.querySelector('.dz-upload-alert').classList.remove('d-none')

    filesToProcess.forEach((file) => {
      const directUpload = new DirectUpload(file, container.dataset.url)

      directUpload.create((error, blob)=>{
        if (!error) {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.value = blob.signed_id
          input.name = container.dataset.name
          container.appendChild(input)
        }

        if (0 === --queueSize) {
          fileInputsForDirectUpload.forEach((input) => {
            input.setAttribute('data-direct-upload-url', container.dataset.url)
          })
          e.target.submit()
        }
      })
    })
  }
}