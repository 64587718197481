import Bugsnag from '@bugsnag/js'

if (process.env.BUGSNAG_API_KEY) {
  window.Bugsnag = Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: process.env.HEROKU_RELEASE_VERSION,
    releaseStage: process.env.NODE_ENV,
    onError: function(event) {
      const error = event.errors[0]
      const params = new URLSearchParams(location.search)
      const fbclidCheck = params.get('fbclid')

      if (fbclidCheck && error.errorClass === 'TypeError' && error.errorMessage === 'Illegal invocation') {
        return false
      }
    }
  })
}
