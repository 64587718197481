const LG_TRESHOLD = 992
const KLASSES = new Array('dropdown-toggle', 'dropdown-menu')

document.addEventListener("turbolinks:load", function() {
  adaptDropdownsForScreen()
  let currentWidth = window.innerWidth

  window.addEventListener('resize', function () {
    const widthOverTreshold = window.innerWidth >= LG_TRESHOLD && currentWidth < LG_TRESHOLD
    const widthUnderTreshold = window.innerWidth < LG_TRESHOLD && currentWidth >= LG_TRESHOLD

    if (widthOverTreshold || widthUnderTreshold) adaptDropdownsForScreen()

    currentWidth = window.innerWidth
  })
})

window.addEventListener('worksaver:restored', adaptDropdownsForScreen)

document.addEventListener('block:added', adaptDropdownsForScreen)

window.addEventListener('block:cloned', adaptDropdownsForScreen)

window.addEventListener('canvas:changed', adaptDropdownsForScreen)

function adaptDropdownsForScreen () {
  if (window.innerWidth >= LG_TRESHOLD) {
    KLASSES.forEach( function (klass) {
      document.querySelectorAll(`.site-${klass}:not(.${klass})`).forEach(
        (element) => element.classList.add(klass)
      )
    })

  } else {
    KLASSES.forEach( function (klass) {
      document.querySelectorAll(`.site-${klass}.${klass}`).forEach(
        (element) => element.classList.remove(klass)
      )
    })
  }
}