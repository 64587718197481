import Rails from "@rails/ujs";

const PAYMENT_SERVICES_COUNTRIES = {
  fedapay: ['bj', 'ci', 'tg', 'sn'],
  cinetpay: []
}

document.addEventListener("turbolinks:load", function() {
  handleOrderForm()
  handleOrderButtons()
})

function getSiteCurrency () {
  return document.head.querySelector('[name="site-currency"]').content
}

function getFedapayKey () {
  return document.head.querySelector('[name="fedapay-key"]').content
}

function dismissCheckoutOverlay () {
  const checkoutLoadingOverlay = document.querySelector('.checkout-loading')
  checkoutLoadingOverlay.style.display = 'none'
}

function submitOrder(action, data) {
  const checkoutLoadingOverlay = document.querySelector('.checkout-loading')
  checkoutLoadingOverlay.style.display = 'block'
  Rails.ajax({
    type: "POST",
    url: action,
    data: data,
    success: dismissCheckoutOverlay,
    error: dismissCheckoutOverlay
  })
}

function handleOrderForm () {
  const form = document.querySelector('.order-form')
  if (!form) return

  let formData

  const emailInput = form.querySelector('input[name="order[customer_email]"]')
  const firstnameInput = form.querySelector('input[name="order[customer_firstname]"]')
  const phoneInput = form.querySelector('input[name="order[customer_phone]"]')
  const lastnameInput = form.querySelector('input[name="order[customer_lastname]"]')
  const paymentMethodGroupSelect = form.querySelector('select[name="order[payment_method_group]"]')

  const onMethodGroupChange = () => {
    const method = paymentMethodGroupSelect.value
    if (method === 'paypal_checkout') {
      emailInput.removeAttribute('required')
      phoneInput.required = true
    } else {
      emailInput.required = true
      phoneInput.removeAttribute('required')
    }
  }

  paymentMethodGroupSelect.addEventListener('change', onMethodGroupChange)
  onMethodGroupChange()

  const getCustomerPhone = () => {
    if (window.intlTelInputGlobals) {
      return window.intlTelInputGlobals.getInstance(phoneInput).getNumber()
    }
    return phoneInput.value
  }

  const renderPaypalButton = () => {
    if (!window.paypal) return

    paypal.Buttons({
      createOrder: function (data, actions) {
        if (!form.checkValidity()) {
          form.reportValidity()
          return false
        }

        return actions.order.create({
          payer: {
            name: {
              given_name: firstnameInput.value,
              surname: lastnameInput.value
            }
          },
          purchase_units: [{
            amount: {
              value: parseFloat(form.dataset.paymentPrice),
              currency_code: getSiteCurrency()
            }
          }],
          application_context: { shipping_preference: "NO_SHIPPING" }
        })
      },

      onApprove: function (data, actions) {
        return  actions.order.capture().then(function(orderData) {
          const purchaseUnit = orderData.purchase_units[0]
          formData = formData || new FormData(form)
          formData.set('order[customer_phone]', getCustomerPhone())
          formData.set('order[payment_service]', 'paypal')
          formData.set('order[price]', purchaseUnit.amount.value)
          formData.set('order[payment_currency]', purchaseUnit.amount.currency_code)
          formData.set('order[external_transaction_id]', orderData.id)
          submitOrder(form.action, formData)
        })
      }
    }).render('#paypal-button-container')
  }

  const makePayment = {}
  makePayment.fedapay = () => {
    if (!window.FedaPay) return

    const widget =  FedaPay.init({
      public_key: getFedapayKey(),
      transaction: {
        amount: parseInt(form.dataset.paymentPrice),
        description: 'Effectuer le paiement'
      },
      customer: {
        email: emailInput.value,
        lastname: firstnameInput.value,
        firstname: lastnameInput.value,
        currency: {
          iso: getSiteCurrency()
        }
      },
      onComplete: (result) => {
        if (result.reason !== FedaPay.CHECKOUT_COMPLETED) return

        const transaction = result.transaction
        formData.set('order[payment_service]', 'fedapay')
        formData.set('order[price]', transaction.amount)
        formData.set(
          'order[payment_currency]',
          { 1: 'XOF' }[transaction.currency_id]
        )
        formData.set('order[external_transaction_id]', transaction.id)
        submitOrder(form.action, formData)
      }
    })
    widget.open()
  }

  form.addEventListener('submit', function(e) {
    e.preventDefault()
    formData = new FormData(form)
    makePayment.fedapay()
  })

  renderPaypalButton()
}

function handleOrderButtons () {
  document.querySelectorAll('[data-order-product]').forEach((button) => {
    const token = button.dataset.orderProduct
    if (!token) return
    button.disabled = true
    Rails.ajax({
      type: 'GET',
      url: `/catalog/${token}`,
      success: (result) => handleOrderButton(button, result.product)
    })
  })
}

function handleOrderButton (button, product) {
  if (window.paypal) {
    button.removeAttribute('disabled')
    const container = document.createElement('div')
    container.id = `paypal-button-container-${window.Helpers.currentTime36()}`
    button.parentElement.insertBefore(container, button)

    for (let i = 0; i < button.attributes.length; i++ ) {
      const attribute = button.attributes[i]
      const nodeName = attribute.nodeName
      if (nodeName.startsWith('data-')) {
        container.setAttribute(nodeName, attribute.nodeValue)
      }
    }

    paypal.Buttons({
      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: parseFloat(product.price),
              currency_code: getSiteCurrency()
            }
          }],
          application_context: { shipping_preference: "NO_SHIPPING" }
        })
      },

      onApprove: function (data, actions) {
        return actions.order.capture().then(
          function (orderData) {
            const purchaseUnit = orderData.purchase_units[0]
            const data = {
              'order[product_id]': product.id,
              'order[payment_service]': 'paypal',
              'order[price]': purchaseUnit.amount.value,
              'order[payment_currency]': purchaseUnit.amount.currency_code,
              'order[external_transaction_id]': orderData.id
            }
            submitOrder('/orders', new URLSearchParams(data))
            return orderData
          }
        )
      }
    }).render(`#${container.id}`)
    button.remove()
    return
  }

  const fedapayKey = getFedapayKey()
  if (!fedapayKey) return

  button.removeAttribute('disabled')
  button.addEventListener('click', function () {
    const widget =  FedaPay.init({
      public_key: fedapayKey,
      transaction: {
        amount: parseInt(product.price),
        description: 'Effectuer le paiement'
      },
      customer: {
        currency: {
          iso: getSiteCurrency()
        }
      },
      onComplete: (result) => {
        if (result.reason !== FedaPay.CHECKOUT_COMPLETED) return
        const transaction = result.transaction
        const data = {
          'order[product_id]': product.id,
          'order[payment_service]': 'fedapay',
          'order[price]': transaction.amount,
          'order[payment_currency]': { 1: 'XOF' }[transaction.currency_id],
          'order[external_transaction_id]': transaction.id
        }
        submitOrder('/orders', new URLSearchParams(data))
      }
    })
    widget.open()
  })
}