function getControlValue (control) {
  const tagName = control.tagName
  if (tagName == 'INPUT' && (control.type === 'checkbox' || control.type === 'radio')) return control.checked
  if (tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') return control.value
}

function setControlValue (control, value) {
  const tagName = control.tagName
  if (tagName === 'INPUT' && (control.type === 'checkbox' || control.type === 'radio')) {
    control.checked = !!value
    control.parentNode.classList.toggle('active', control.checked)
    control.dispatchEvent(new Event('helper:change'))
    return true
  } else if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
    control.value = value
    return true
  }

  if (tagName === 'SELECT') return setSelectedValue(control, value)
}

function setSelectedValue (selectElement, value) {
  for (let i=0; i < selectElement.options.length; ++i ) {
    const option = selectElement.options[i]
    if (option.value == value) {
      selectElement.selectedIndex = i
      return true
    }
  }
  return false
}

function extractPixelValue (sizeInPixel) {
  if (!sizeInPixel) return sizeInPixel
  return sizeInPixel.substring(0, sizeInPixel.length - 2)
}

function changeTagName(element, tagName) {
  if (element.tagName === tagName) return element

  const newElement = document.createElement(tagName.toLowerCase())
  newElement.innerHTML = element.innerHTML

  for(let i = 0, l = element.attributes.length; i < l; ++i){
    const nodeName  = element.attributes.item(i).nodeName
    const nodeValue = element.attributes.item(i).nodeValue
    newElement.setAttribute(nodeName, nodeValue)
  }

  element.replaceWith(newElement)
  return newElement
}

function replaceElement(element, outerHTML){
  let parent = false, refElement
  if (element.previousElementSibling) {
    refElement = element.previousElementSibling
  } else {
    refElement = element.parentElement
    parent = true
  }

  const div = document.createElement('div')
  div.innerHTML = outerHTML

  element.replaceWith(div.firstElementChild)


  if (parent) return refElement.firstElementChild

  return refElement.nextElementSibling
}

function currentTime36 () {
  return Math.round(new Date().getTime() / 100).toString(36)
}

function isInt (stringValue) {
  return parseInt(stringValue).toString() === stringValue
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

window.Helpers = {
  setSelectedValue: setSelectedValue,
  getControlValue: getControlValue,
  setControlValue: setControlValue,
  extractPixelValue: extractPixelValue,
  changeTagName: changeTagName,
  replaceElement: replaceElement,
  currentTime36: currentTime36,
  isInt: isInt,
  isValidHttpUrl: isValidHttpUrl,
  activeStorageBlobExtractor: /\/(?:blobs|representations)\/(?:redirect\/){0,1}((\w|\-)*)/
}