import { Controller } from "stimulus"
import Rails from '@rails/ujs'

const commentsPerPage = 15
const repliesPerPage = 10

export default class extends Controller {
  static targets = ['loader', 'commentArea', 'commentActions', 'comments']

  connect () {
    this.connectedAt = new Date().toISOString()
    this.articleId = this.element.dataset.articleId
    this.isAccountMember = this.element.dataset.accountMember === 'true'
    this.userId = this.element.dataset.userId
    this.isAuthenticated = !!this.userId
    this.hasName = !!this.element.dataset.userName
    this.commentsCount = this.element.dataset.commentsCount
    this.page = 1
  }

  emptyAllSubscribeCheckboxWrappers () {
    this.element.querySelectorAll(
      '.subscribe-checkbox-wrapper'
    ).forEach(
      (wrapper) => {
        wrapper.removeAttribute('class')
        wrapper.innerHTML = ''
      }
    )
  }

  postComment (e) {
    e.preventDefault()
    const textarea = e.target.querySelector('textarea')
    if (!textarea.value) {
      return
    }

    if (!this.verifyCaptchaPresence(e.target)) {
      return
    }

    this.disableSubmitButton(e)
    const formData = new FormData(e.target)
    Rails.ajax({
      type: "POST",
      url: e.target.action,
      data: formData,
      success: (response) => {
        showFlash(response.notice, false)
        if (response.subscribed) {
          showFlash(I18n.subscribers.create_success, false)
          this.emptyAllSubscribeCheckboxWrappers()
        }
        this.commentsTarget.insertAdjacentHTML('afterbegin', response.html)
        this.enableSubmitButton(e)
        if (this.isAuthenticated) {
          this.commentActionsTarget.classList.add('d-none')
        } else {
          $(this.commentActionsTarget).collapse('hide')
        }
        textarea.value = null
      },
      error: (response) => {
        this.enableSubmitButton(e)
        textarea.value = null
        showFlash(response.error, true)
      }
    })
  }

  postReply (e) {
    e.preventDefault()
    const textarea = e.target.querySelector('textarea')
    if (!textarea.value) {
      return
    }

    if (!this.verifyCaptchaPresence(e.target)) {
      return
    }

    this.disableSubmitButton(e)

    const commentId = e.target.dataset.commentId
    const formData = new FormData(e.target)

    Rails.ajax({
      type: "POST",
      url: `/comments/${commentId}/reply`,
      data: formData,
      success: (response) => {
        showFlash(response.notice, false)
        if (response.subscribed) {
          showFlash(I18n.subscribers.create_success, false)
          this.emptyAllSubscribeCheckboxWrappers()
        }
        const commentElement = e.target.closest('.comment:not(.reply)')
        const userRepliesElement = commentElement.querySelector('.comment-user-replies')

        userRepliesElement.insertAdjacentHTML('beforeend', response.html)
        userRepliesElement.scrollIntoView({behavior: 'smooth', block: 'center'})

        this.hideReplyForm(e)
        this.enableSubmitButton(e)
      },
      error: (response) => {
        textarea.value = null
        this.enableSubmitButton(e)
        this.hideReplyForm(e)
        showFlash(response.error, true)
      }
    })
  }

  loadMoreComments (e) {
    if (this.commentsCount <= this.page * commentsPerPage) return
    const element = e.currentTarget
    this.hideElementAndShowSibling(element)

    Rails.ajax({
      type: "GET",
      url: `/comments?article_id=${this.articleId}&created_at=${this.connectedAt}&page=${this.page + 1}`,
      success: (response) => {
        this.commentsTarget.insertAdjacentHTML('beforeend', response.html)
        this.page++
        if (commentsPerPage * this.page >= this.commentsCount) {
          this.hideElementAndSiblings(element)
        } else {
          this.showElementAndHideSibling(element)
        }
      },
      error: (response) => {
        this.showElementAndHideSibling(element)
      }
    })
  }

  transferUserReplies(e) {
    const commentElement = e.target.closest('.comment')
    const userRepliesElement = commentElement.querySelector('.comment-user-replies')
    const userReplies = userRepliesElement.innerHTML
    if (!userReplies) return

    const repliesElement = commentElement.querySelector('.comment-replies')
    userRepliesElement.innerHTML = ''
    repliesElement.insertAdjacentHTML('beforeend', userReplies)
  }

  loadReplies (e) {
    if (e.target.dataset.repliesLoaded === 'true') return
    e.target.closest('.comment').querySelector('.load-button').click()
    e.target.dataset.repliesLoaded = 'true'
  }

  loadMoreReplies (e) {
    this.hideElementAndShowSibling(e.currentTarget)
    const element = e.currentTarget
    const commentId = element.dataset.commentId
    const page = parseInt(element.dataset.repliesPage) + 1
    const repliesCount = parseInt(element.dataset.repliesCount)

    const commentElement = element.closest('.comment')
    const repliesElement = commentElement.querySelector('.comment-replies')
    const userRepliesElement = commentElement.querySelector('.comment-user-replies')
    const userReplies =  userRepliesElement.innerHTML
    userRepliesElement.innerHTML = ''

    Rails.ajax({
      type: "GET",
      url: `/comments/${commentId}/replies?page=${page}&created_at=${this.connectedAt}`,
      success: (response) => {
        repliesElement.insertAdjacentHTML('beforeend', response.html)
        if (userReplies) {
          repliesElement.insertAdjacentHTML('beforeend', userReplies)
        }

        if (repliesPerPage * page >= repliesCount) {
          this.hideElementAndSiblings(element)
        } else {
          this.showElementAndHideSibling(element)
        }
        element.dataset.repliesPage = page
      },
      error: (response) => {
        this.showElementAndHideSibling(element)
        if (userReplies) {
          userRepliesElement.innerHTML = userReplies
        }
      }
    })
  }

  verifyCaptchaPresence (form) {
    if (this.isAuthenticated) return true
    const captcha = form.querySelector('[name="h-captcha-response"]')
    if (!captcha || !captcha.value) {
      showFlash(I18n.hcaptcha.humanity_unconfirmed, true)
      return false
    }
    return true
  }

  showCommentActions () {
    this.commentActionsTarget.classList.remove('d-none')
  }

  hideCommentActions () {
    this.commentActionsTarget.classList.add('d-none')
    this.commentAreaTarget.value = ''
  }

  showReplyForm (e) {
    if (!this.isAuthenticated) {
      this.redirectToLoginPage(e)
      return
    } else if (!this.hasName) {
      this.redirectToEditNamePage(e)
      return
    }

    const commentElement = e.target.closest('.comment')
    const replyForm = commentElement.querySelector('form.reply-form')
    $(replyForm).collapse('show')
  }

  hideReplyForm (e) {
    const replyForm = e.target.closest('form')
    replyForm.querySelector('textarea').value = ''
    $(replyForm).collapse('hide')
  }

  disableSubmitButton (e) {
    e.target.querySelector('input[type=submit]').setAttribute('disabled', true)
  }

  enableSubmitButton (e) {
    e.target.querySelector('input[type=submit]').removeAttribute('disabled')
  }

  showElementAndHideSibling (element) {
    element.classList.remove('d-none')
    element.nextElementSibling.classList.add('d-none')
  }

  hideElementAndShowSibling (element) {
    element.classList.add('d-none')
    element.nextElementSibling.classList.remove('d-none')
  }

  hideElementAndSiblings (element) {
    element.parentElement.classList.add('d-none')
  }

  deleteComment (e) {
    const commentElement = e.target.closest('.comment')
    const commentId = commentElement.dataset.commentId

    Rails.ajax({
      type: "DELETE",
      url: `/comments/${commentId}`,
      success: (response) => {
        commentElement.remove()
      }
    })
  }

  redirectToLoginPage (e) {
    e.preventDefault()
    window.location = this.element.dataset.loginUrl
  }

  redirectToEditNamePage (e) {
    e.preventDefault()
    window.location = this.element.dataset.loginUrl.replace('sign_in?', 'edit?')
  }

  disconnect () {
    this.commentAreaTarget.removeEventListener('click', this.redirectLtn)
  }
}
