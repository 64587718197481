import Pickr from "@simonwep/pickr";

function fixPickrIssue246 (input) {
  // Fix: bug with Safari 14 not re-rendering color https://github.com/Simonwep/pickr/issues/246
  setTimeout(function () {
    input.parentElement.style.display = 'none'
    setTimeout(function () {
      input.parentElement.style.display = null
    }, 0)
  }, 0)
}

export default class ColorPicker {
  constructor(input) {
    this.input = input
    if (input.parentNode.querySelector('.pickr')) {
      return
    }
    input.classList.add('d-none')
    const el = document.createElement('div')
    input.parentNode.insertBefore(el, input.nextSibling)
    this.opened = false
    this.pickr = Pickr.create({
      el: el,
      theme: 'nano',
      default: input.value,
      defaultRepresentation: 'HEX',
      position: input.dataset.colorpickerPosition || 'bottom-middle',

      swatches: [
        input.value || 'rgb(244, 67, 54)',
        'rgb(233, 30, 99)',
        'rgb(156, 39, 176)',
        'rgb(103, 58, 183)',
        'rgb(63, 81, 181)',
        'rgb(33, 150, 243)',
        'rgb(3, 169, 244)',
        'rgb(0, 188, 212)',
        'rgb(0, 150, 136)',
        'rgb(76, 175, 80)',
        'rgb(139, 195, 74)',
        'rgb(205, 220, 57)',
        'rgb(255, 235, 59)',
        'rgb(255, 193, 7)'
      ],

      components: {
        preview: true,
        hue: true,
        opacity: !input.hasAttribute('data-no-opacity'),

        interaction: {
          input: true,
          save: true
        }
      },

      strings: {
        save: I18n.choose
      }
    })

    this.opened = false

    this.pickr.on('show', () => {
      this.opened = true
    })

    this.pickr.on('hide', (i) => {
      this.opened = false
    })

    this.pickr.on('save', (i) => {
      this.pickr.hide()
    })

    this.pickr.on('change', (i) => {
      if (!this.opened) return

      const value = '#' + i.toHEXA().join('')

      if (input.value == value) return

      input.value = value
      input.dispatchEvent(new Event('change'))
      this.pickr.applyColor(true)

      fixPickrIssue246(input)
    })

    input.addEventListener('pickr-change', (e) => {
      this.pickr.setColor(e.target.value, true)
      this.pickr.applyColor(true)

      fixPickrIssue246(input)
    })

    fixPickrIssue246(input)

    Array.from(['foreground', 'background']).forEach((type) => {
      if (input.hasAttribute(`data-${type}`)){
        const root = this.pickr.getRoot().root
        root.classList.add('pickr-variant')
        const button = root.querySelector('.pcr-button')
        button.classList.add('pcr-button-small')
        const icon = document.createElement('div')
        icon.classList.add(`pcr-icon-${type}`)
        if (type === 'foreground') {
          icon.innerHTML = '<div>A</div>'
        }
        root.insertBefore(icon, button)
        root.addEventListener('click', (e)=>{
          if (this.opened) {
            this.opened = false
            return
          }
          if (e.target.tagName === 'BUTTON') return
          button.click()
          this.opened = true
        })
        return
      }
    })
  }

  static buildColorPickersOnLoad () {
    document.addEventListener("turbolinks:load", function() {
      const inputs = document.querySelectorAll('[data-colorpicker]')
      inputs.forEach(function (input) {
        new ColorPicker(input)
      })
    })
  }
}
