import intlTelInput from 'intl-tel-input'

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.tel-input').forEach(function(input) {
    const params = {
      separateDialCode: true,
      initialCountry: 'bj',
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
    }

    if (input.dataset.telCountries) {
      params.onlyCountries = input.dataset.telCountries.split(',')
    }

    intlTelInput(input, params)
  })
})