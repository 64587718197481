import Timer from 'easytimer.js'
import dayjs from 'dayjs'

document.addEventListener('turbolinks:load', function () {
  const elements = document.querySelectorAll('[data-countdown]')

  if (!elements) {
    return
  }

  elements.forEach((element) => {
    if (!element.dataset.countdown) return

    const now = dayjs()
    const time = dayjs(element.dataset.countdown)
    const timer = new Timer({
      countdown: true,
      startValues: [0, time.diff(now, 's'), 0, 0, 0]
    })
    const seconds = element.querySelector('[data-countdown-seconds]')
    const minutes = element.querySelector('[data-countdown-minutes]')
    const hours = element.querySelector('[data-countdown-hours]')
    const days = element.querySelector('[data-countdown-days]')

    timer.start()

    timer.addEventListener('secondsUpdated', function (e) {
      const values = timer.getTimeValues()
      seconds.innerHTML = values.seconds
      minutes.innerHTML = values.minutes
      hours.innerHTML = values.hours
      days.innerHTML = values.days
    })
  })
})
