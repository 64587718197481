document.addEventListener("turbolinks:load", function() {
  renderHcapthas()
  handleHcapthaOverlayTriggers()
})

function renderHcapthas () {
  if (!document.querySelector('.h-captcha')) return

  const sitekey = document.querySelector("[name='hcaptcha-site-key']").content
  const script = document.createElement('script')
  script.src = "https://hcaptcha.com/1/api.js?render=explicit"
  script.addEventListener('load', function () {
    document.querySelectorAll('.h-captcha').forEach((element) => {
      element.innerHTML = ""
      element.dataset.sitekey = sitekey
      hcaptcha.render(element)
    })
  })
  document.head.appendChild(script)
}

function handleHcapthaOverlayTriggers () {
  document.querySelectorAll('input[data-captcha-trigger]').forEach(
    function (input) {
      input.addEventListener('click', function (e) {
        e.preventDefault()
        const form = input.form
        if (!form.checkValidity()) {
          form.reportValidity()
          return
        }
        showHcaptchaOverlay(form)
      })
    }
  )
}

function showHcaptchaOverlay (form) {
  const hcaptchaOverlay = document.querySelector('.h-captcha-overlay')
  if (!hcaptchaOverlay) return
  const targetResponseTextarea = form.querySelector('textarea[name="h-captcha-response"]')
  if (!targetResponseTextarea) return

  const cancelButton = hcaptchaOverlay.querySelector('.h-captcha-cancel')
  const continueButton = hcaptchaOverlay.querySelector('.h-captcha-continue')

  const proceed = function () {
    const responseTextarea = hcaptchaOverlay.querySelector('textarea[name="h-captcha-response"]')
    if (!responseTextarea) return
    if (!responseTextarea.value) {
      showFlash(I18n.hcaptcha.test_not_validated, true)
      return
    }
    targetResponseTextarea.value = responseTextarea.value
    cancelButton.click()
    setTimeout( () => form.dispatchEvent(new Event('hcaptcha:after-validate')), 500)
  }

  const cancel = function () {
    hcaptchaOverlay.style.display = 'none'
    hcaptchaOverlay.classList.remove("animate__fadeIn")
    hcaptchaOverlay.classList.remove("animate__animated")
    cancelButton.removeEventListener('click', cancel)
    continueButton.removeEventListener('click', proceed)
  }


  cancelButton.addEventListener('click', cancel)

  continueButton.addEventListener('click', proceed)

  hcaptchaOverlay.style.display = 'block'
  hcaptchaOverlay.classList.add("animate__animated")
  hcaptchaOverlay.classList.add("animate__fadeIn")
}