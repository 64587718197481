document.addEventListener("turbolinks:load", handleImgRedirection)

function handleImgRedirection() {
  document.querySelectorAll('img[data-href]').forEach(function(element) {
    element.style.cursor = 'pointer'
    element.addEventListener('click', function (e) {
      window.location.href = element.dataset.href
    })
  })
}
