document.addEventListener("turbolinks:load", function() {
  let mainImage = document.querySelector('.product-main_image')
  if (!mainImage) return
  const mainImageClass = mainImage.getAttribute('class')

  const images = document.querySelectorAll('.product-image')

  images.forEach((image) => {
    image.addEventListener('click', function () {
      const newMainImage = image.cloneNode()
      newMainImage.setAttribute('class', mainImageClass)
      mainImage.parentElement.replaceChild(newMainImage, mainImage)
      mainImage = newMainImage
      images.forEach((img) => img.classList.remove('border'))
      image.classList.add('border')
    })
  })
})